<template>
  <div class="fixed top-0 left-0 right-0">
    <div class="py-1 lg:py-3 lg:px-8 bg-white border-b border-gray-200 drop-shadow-md">
      <nav class="max-w-screen-xl mx-auto flex items-center">
        <div class="w-20 md:w-64">
          <button
            class="p-4 activate-on-load flex gap-2"
            @click="goBack()"
          >
            <SvgIcon
              name="arrow-left"
              class="w-6 h-6"
            />
            <span class="hidden md:block">
              oatsovernight.com
            </span>
          </button>
        </div>
        <div class="grow flex justify-center">
          <a
            href="https://www.oatsovernight.com"
            class="block"
            data-gtm-category="navigation"
            data-gtm-action="website header"
            data-gtm-label="logo"
          >
            <SvgIcon
              name="oats-overnight"
              style="width: 51px; height: 56px;"
            />
          </a>
        </div>
        <div class="w-20 md:w-64" />
      </nav>
    </div>
  </div>
</template>

<script setup lang="ts">
const goBack = () => window.history.back()
</script>
