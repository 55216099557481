<template>
  <WebsiteHeader v-if="wasReferred" />
  <div
    data-screen="authentication"
    class="p-4 flex items-center justify-center h-screen"
  >
    <div class="flex flex-col grow  gap-3 xs:gap-6 p-8 max-w-screen-xs mx-auto bg-white rounded-lg shadow-sm">
      <img
        v-if="!wasReferred"
        src="@/assets/oats-overnight.svg"
        alt="Oats Overnight"
        class="w-12 xxs:w-18 xs:w-20 mx-auto"
        width="80"
        height="88"
      >
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
const { public: { shopifyUrl } } = useRuntimeConfig()
const wasReferred = computed(() => {
  const { referrer } = document
  return referrer.includes(shopifyUrl) ||
         referrer.includes('www.oatsovernight.com')
})
</script>
